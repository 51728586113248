import "./ColorSays.css";

export const ColorSays = () => {
  return (
    <div class="color-says">
      <div class="header">

      </div>
      <div class="board">
        <div class="square"></div>
        <div class="square"></div>
        <div class="square"></div>
        <div class="square"></div>
      </div>
    </div>
  );
};

export default ColorSays;
